//申请记录
export const ApplicationRecordDetailsColumn = [{
  prop: "userName",
  label: "账户",
  width: 100,
},
{
  prop: "realName",
  label: "用户名",
},

{
  prop: "createTime",
  label: "创建时间",
},
{
  prop: "updateTime",
  label: "最后登录时间",
},
{
  prop: "operator",
  label: "操作人",
  width: 80,
},

{
  prop: "operation",
  label: "操作",
  width: 140,
  template: true,
},
]
