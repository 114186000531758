<template>
  <div class="applicationRecord">
    <h3>账户管理</h3>
    <!-- 列表搜索条件，已封装 -->
    <!-- 根据条件搜索 -->
    <div class="searchItem">
      <el-form :model="form" label-width="80px" inline ref="form">
        <el-form-item label="用户名：">
          <el-input
            style="width: 190px"
            v-model="form.realName"
            placeholder="请输入"
          >
          </el-input>
        </el-form-item>
        <div>
          <el-button class="query-btn" type="primary" @click="search"
            >搜索</el-button
          >
          <el-button class="query-btn" type="primary" @click="reset"
            >重置</el-button
          >
          <el-button type="primary" @click="addAccount">新增</el-button>
        </div>
      </el-form>
    </div>
    <ShowEdit v-if="showEdit" @closeDialog="closeDialog" ref="ShowEdit" />

    <!-- 账户表格表格 -->
    <!-- @selectionChange="handleSelection" -->
    <div class="table-content">
      <table-page
        :data="tableData"
        :columns="columns"
        :currentPage="query.pnum"
        :pageSize="query.psize"
        :total="total"
        :loading="loading"
        :border="true"
        @sizeChange="handleSizeChange"
        @currentChange="handleCurrentChange"
      >
        <template slot="operation" slot-scope="scope">
          <el-button type="text" size="small" @click="editClick(scope.row)"
            >修改</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="delClick(scope.row.userId)"
            >删除</el-button
          >
        </template>
      </table-page>
    </div>
  </div>
</template>

<script>
import TablePage from "@/components/table/table-page";
import ShowEdit from "./component/ShowEdit";
import { ApplicationRecordDetailsColumn } from "./tableColumn";
import { selectRoleList, UserDelete } from "@/api/RuralFinance";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tableData: [],
      columns: ApplicationRecordDetailsColumn,
      loading: false,
      keyForm: 1,
      total: 0,
      showEdit: false, //详情
      query: {
        pnum: 0,
        psize: 10,
      },
      form: {
        realName: "",
      },
    };
  },
  components: {
    TablePage,
    ShowEdit,
  },
  computed: {
    ...mapGetters(["identityObj"]),
  },
  mounted() {
    this.selectRoleList();
  },

  methods: {
    async selectRoleList() {
      let content = localStorage.getItem("userInfo");
      let deptId = JSON.parse(content).content.deptId;
      console.log("deptId", deptId);
      let params = {
        deptId: deptId,
        pnum: this.query.pnum,
        psize: this.query.psize,
      };
      let res = await selectRoleList(params);
      this.tableData = res.data;
      this.total = res.dataNum;
    },
    // 新增编辑关闭弹框
    closeDialog(showEdit) {
      this.showEdit = showEdit;
      this.selectRoleList();
    },
      // 显示发布的弹框
    addAccount() {
      this.showEdit = true;
      this.$nextTick(() => {
        this.$refs.ShowEdit.addObj();
      });
    },
    // 进行修改
    editClick(obj) {
      this.showEdit = true;
      this.$nextTick(() => {
        this.$refs.ShowEdit.editObj(obj);
      });
    },

    // 进行搜索
    async search() {
      let content = localStorage.getItem("userInfo");
      let deptId = JSON.parse(content).content.deptId;
      let params = {
        ...this.form,
        deptId: deptId,
        pnum: 1,
        psize: 10,
      };
      let res = await selectRoleList(params);
      this.tableData = res.data;
      this.total = res.dataNum;
    },
    //分页单页切换
    handleCurrentChange(page) {
      console.log(page);
      this.query.pnum = page;
      this.selectRoleList();
    },
    //分页总页数切换
    handleSizeChange(size) {
      this.query = {
        pnum: 1,
        psize: size,
      };
      this.selectRoleList();
    },
    //重置
    reset() {
      this.form.realName='';
      this.selectRoleList();
    },
    //删除
    async delClick(id) {
      this.$confirm("此操作将删除此用户", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {

          let res = await UserDelete(id);
          // this.selectRoleList();
          if(res.code==0){
            this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.selectRoleList();
          }else{
            this.$message({
            type: "error",
            message: res.msg,
          });
          }

        })
        .catch(() => {
          console.log('2')
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
/*根据条件搜索*/
.searchItem {
  .el-form {
    width: 100%;
    display: flex;
  }

  /deep/.el-form-item {
    width: 25% !important;
    .el-input {
      width: 200px;
    }
    .el-form-item__label {
      width: 100px !important;
    }
    .el-form-item__content {
      width: 200px;
    }
    .el-date-editor {
      width: 200px;
    }
  }
}
</style>
