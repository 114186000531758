<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :title="isEdit ? '编辑子账户' : '新增子账户'"
      width="40%"
      height="1000px"
      ref="Edit"
      center
    >
      <div class="Editor">
        <el-form :model="form" :rules="rules" label-position="left" ref="form">
          <el-form-item label="手机号 (账户):" prop="userName">
            <el-input v-model="form.userName" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="用户名:" props="realname">
            <el-input
              v-model="form.realname"
              placeholder=""
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="密码:"
            prop="password"
            autocomplete="new-password"
          >
            <el-input type="text" style="display: none"> </el-input>
            <el-input
              v-model="form.password"
              type="password"
              autocomplete="new-password"
              style="background-color: #ffffff !important"
              readonly
              onfocus="this.removeAttribute('readonly');"
              onblur="this.setAttribute('readonly',true);"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户类型:">
            <el-select  :disabled=true v-model="form.roleId">
              <el-option
                v-for="item in types"
                :key="item.roleId"
                :label="item.label"
                :value="item.roleId"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <div class="StartupStatu">
            <span>启用状态:</span>
            <div class="state">
              <el-radio v-model="form.radio" label="0">启用</el-radio>
              <el-radio v-model="form.radio" label="1">禁用</el-radio>
            </div>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Cancel()">取 消</el-button>
        <el-button type="primary" @click="submit()">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { editInfo, UserAdd } from "@/api/RuralFinance";
export default {
  data() {
    return {
      dialogVisible: true, //弹窗开启状态
      form: {
        userName: "",
        realname: "",
        password: "",
        radio: "", //启用状态
        roleId: "",
      },
      types: [
        {
          roleId: 7,
          label: "市级政府人员",
        },
        {
          roleId: 6,
          label: "县级政府人员",
        },
        {
          roleId: 22,
          label: "镇级政府人员",
        },
        {
          roleId: 15,
          label: "银行人员",
        },
        {
          roleId: 17,
          label: "会计人员",
        },

      ],
      rules: {
        realname: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
            { min: 3, max: 20, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        userName: [
          // 添加正则表达式 pattern: /^1[3|5|7|8|9]\d{9}$/，验证手机号是否正确
          { required: true, message: "请输入手机号", trigger: "change" },
          {
            pattern: /^1[3|5|7|8|9]\d{9}$/,
            message: "请输入正确的号码格式",
            trigger: "blur",
          },
        ],
      },
      isEdit: false, //当前页面状态：编辑还是新增
      userId: "",
    };
  },
  computed: {
    ...mapGetters(["identityObj"]),
  },

  methods: {
    // 取消弹窗按钮
    Cancel() {
      this.$emit("closeDialog", false);
    },
    handleClose() {
      this.$emit("closeDialog", false);
    },

    // 提交
    async submit() {
      this.$refs.form.validate(async (valid, obj) => {
        if (valid) {
          if (this.isEdit) {
            let content = localStorage.getItem("userInfo");
            let deptId = JSON.parse(content).content.deptId;
            if (this.form.password) {
              let params = {
                username: this.form.userName,
                realname: this.form.realname,
                lockFlag: this.form.radio,
                password: this.form.password,
                roleId: this.form.roleId,
                role: [this.form.roleId],
                deptId: deptId,
                userId: this.userId,
              };
              let res = await editInfo(params);
              console.log(params, "查看params");
              if (res.data.code == 0) {
                this.$message.success("修改成功");
                this.$emit("closeDialog", false);
              } else {
                this.$message.info(res.data.code);
              }
            } else {
              let params = {
                username: this.form.userName,
                realname: this.form.realname,
                lockFlag: this.form.radio,
                roleId: this.form.roleId,
                role: [this.form.roleId],
                deptId: deptId,
                userId: this.userId,
              };
              let res = await editInfo(params);
              console.log(params, "查看params");
              this.$message.success("修改成功");
            }
          } else {
            let content = localStorage.getItem("userInfo");
            let deptId = JSON.parse(content).content.deptId;
            if (this.form.password) {
              let params = {
                username: this.form.userName,
                realname: this.form.realname,
                lockFlag: this.form.radio,
                password: this.form.password,
                roleId: this.form.roleId,
                role: [this.form.roleId],
                deptId: deptId,
                userId: this.userId,
              };

              let res = await UserAdd(params);

              if (res.data.code == 0) {
                this.$message.success(
                  '新增成功'
                );
                this.$emit("closeDialog", false);
              } else {
                this.$message.info(res.data.msg);
              }
            } else {
              this.$message.info("请输入密码");
            }
          }
        } else {
          this.$message.error("请完善信息");
        }
      });

      // this.$emit("closeDialog", false);
    },

    // 回显
    editObj(obj) {
      this.isEdit = true;
      if (this.isEdit) {
        this.form.userName = obj.userName;
        this.form.realname = obj.realName;
        this.form.radio = obj.lockFlag;
        this.userId = obj.userId;
        this.form.roleId = obj.roleId;
      }
    },
    addObj(){
      let num=this.identityObj.identityTypeCode
      console.log(num ,'查看这时候的用户类型是多少')
      switch(num){
           //市级管理员 24   7
        case "24":
        this.form.roleId=7;
        break ;
         //县级管理员 19   6
        case "19":
        this.form.roleId=6;
        break;
          //镇级管理员  23    22
        case "23":
        this.form.roleId= 22;
        break;
          // 银行主管 18   15
        case "18":
        this.form.roleId=15;
        break;
          //会计主管  16    17
        case "16":
        this.form.roleId=17;
        break;
      }
      console.log(this.form.roleId,'查看roleId')
    // if(num==19){
    //   this.form.roleId=6;
    // }else{
    //   this.form.roleId=15;
    // }

    }
  },
};
</script>

<style lang="less" scoped>
.Editor {
  /deep/.el-form-item {
    display: flex;
    .el-form-item__label {
      width: 20%;
    }
  }
  .StartupStatu {
    display: flex;
    .state {
      margin-top: 30px;
      display: flex;
    }
    span {
      width: 18%;
    }
  }
}
</style>
